.App{
    position: absolute;
    display: flex;
    width: 100% !important;
    height: 100% !important;
    justify-content: space-between;
    flex-direction: column;
}
#map{
    flex-grow: 10;
    width: 100vw;
    height: 100vh;
    max-height: 100% !important;
}
